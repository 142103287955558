import { fromJS } from 'immutable';
import _get from 'lodash/get';
import { timeService } from '@/services/instances/timeService';
import { DATE_PICKER_WEEK_DATE } from '@/utils/constants';
import { config } from '@/config';
import type { TimeSheetModelState, TimeSheetPayloadAction } from './types';
import { TimeSheetModelActions, TimeSheetModelStateFields } from './types';
import type { Reducer } from 'redux';

const presentDay = new Date();

const initialState = fromJS({}).withMutations((state) => {
  const { date } = JSON.parse(window.localStorage.getItem(DATE_PICKER_WEEK_DATE) || '{}');
  const currentTimesheetDateObj = date ? new Date(date) : presentDay;

  state.setIn([TimeSheetModelStateFields.WEEK_BETWEEN_DATE], [
    timeService.startOfWeek(currentTimesheetDateObj),
    timeService.endOfWeek(currentTimesheetDateObj),
  ]);
  state.setIn([TimeSheetModelStateFields.WEEK_ACTIVE_USER], config?.userId);
});

export const timeSheetModelReducer: Reducer<TimeSheetModelState, TimeSheetPayloadAction> = (
  state: TimeSheetModelState = initialState,
  action: TimeSheetPayloadAction = {} as TimeSheetPayloadAction,
): TimeSheetModelState => {
  switch (action.type) {

    case TimeSheetModelActions.UPDATE_WEEK_BETWEEN_DATE: {
      const { data } = _get(action, ['payload']);

      return state.setIn([TimeSheetModelStateFields.WEEK_BETWEEN_DATE], data);
    }

    case TimeSheetModelActions.UPDATE_WEEK_ACTIVE_USER: {
      const userId = _get(action, ['payload']);

      return state.setIn([TimeSheetModelStateFields.WEEK_ACTIVE_USER], userId);
    }

    default: {
      return state;
    }
  }
};
