import type { IWeekStart } from '@/services/TimeServiceFactory/types';
import { config } from '@/config';

export const getWeekStartSettings = () => {
  const {
    groupSetting: {
      weekStart = '1',
    } = {},
  } = config || {};

  return parseInt(weekStart || '1') as IWeekStart;
};
