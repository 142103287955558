import { APIError } from '@/services/RestApiClientFactory/ApiErrorHandler/ApiError/ApiError';
import { ApiClientBase } from '@/services/RestApiClientFactory/modules/ApiClientBase';
import type {
  JiraV2IndividualConnectionsResult,
  JiraV2SaveSettingsParams, JiraV2SaveSettingsResponse, JiraV2SendInvitationsResponse,
} from '@/services/RestApiClientFactory/modules/ApiClientJiraV2/ApiClientJiraV2.types';
import { getCSRFCookieValue, getUrlEncodedBodyFromJsonBody } from '@/utils/common';
import { config } from '@/config';

export class ApiClientJiraV2 extends ApiClientBase {
  basePath = 'addons/jira_V2';

  async save(params: JiraV2SaveSettingsParams) {
    const { csrfEnabled, csrfTokenName, csrfCookieName } = config;
    const csrfToken = getCSRFCookieValue(csrfCookieName);
    if (csrfEnabled && csrfToken) {
      params[csrfTokenName] = csrfToken;
    }

    const result = await this.fetchData<JiraV2SaveSettingsResponse>({
      queryString: `${this.basePath}/save`,
      method: 'POST',
      body: getUrlEncodedBodyFromJsonBody(params),
      additionalHeaders: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      apiVersion: 0,
    });

    if (!result.ok) {
      throw new APIError('Failed to save settings', result.status);
    }

    return result;
  }

  async getIndividualConnections() {
    const result = await this.fetchData<JiraV2IndividualConnectionsResult>({
      queryString: `${this.basePath}/individualConnections`,
      apiVersion: 0,
      method: 'GET',
    });

    if (!result.ok) {
      throw new APIError('Failed to get individual connections', result.status);
    }

    return result;
  }

  async getReportIndividualConnections() {
    const result = await this.fetchData<Response>({
      queryString: `${this.basePath}/getReportIndividualConnections`,
      apiVersion: 0,
      method: 'GET',
    });

    if (!result.ok) {
      throw new APIError('Failed to get report individual connections', result.status);
    }

    return result;
  }

  async sendInvitations() {
    const result = await this.fetchData<JiraV2SendInvitationsResponse>({
      queryString: `${this.basePath}/sendInvitations`,
      apiVersion: 0,
      method: 'GET',
    });

    if (!result.ok || result.data.status === false) {
      throw new APIError('Failed to send invitations', result.status);
    }

    return result;
  }
}
